import {createPortal} from 'react-dom';
import {ReactComponent as Cancel} from "../asseets/svgs/cancel.svg";

const Drawer = ({body, isOpen, onClose, heading}) => {
    return (
        <div>
            {
                isOpen && createPortal(<div className={'drawerWrapper'}>
                    <div className={'blackScreen'} style={{
                        alignItems: 'flex-start'
                    }}>
                        <div className={'drawerBody'}>
                            <div className={'drawerHeader'}>
                                <span className={'drawerHeaderText'}>{heading}</span>
                                <Cancel style={{cursor: 'pointer', height: '25px', width: '25px'}} height={30}
                                        onClick={onClose}/>
                            </div>
                            {body}
                        </div>
                    </div>
                </div>, document.body)
            }
        </div>
    )
}

export default Drawer;