import React from "react";
import {socialPlatformsList} from "../../constants";

const svgStyles = {
    height: '38px',
    width: '38px',
    cursor: 'pointer'
}
export const ContactInfo = () => {
    const renderSocialIcons = () => {
        return (
            <React.Fragment>
                {
                    socialPlatformsList.map(({url, Icon, styles}) => {
                        return (
                            <a href={url} target={'_blank'}>
                                <Icon style={{...svgStyles, ...styles}}/>
                            </a>
                        )
                    })
                }
            </React.Fragment>
        )
    }

    return (
        <div className={'contactInfoWrapper'}>
            <span>For comments and suggestions</span>
            <span>Please contact: 0300-4200703</span>
            <span>Farough Gillani</span>
            <hr style={{width: '100%'}}/>
            <div className={`drawer-social-icon-wrapper`}>
                {renderSocialIcons()}
            </div>
        </div>
    )
}