import React from 'react';
import H5AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';
import { connect } from 'react-redux'
import {musicSetter} from "../../redux/actions/musicActions";

const AudioPlayer = (props) => {
    const { music, musicList, dispatch } = props;

    const setNextOrPreviousMusic = (seek) => {
        dispatch(musicSetter({
            ...musicList[seek],
            parent: music.parent
        }))
    }

    const onClickNext = () => {
        const currentIndexOfSong = musicList.map(i => i.path).indexOf(music.path)
        const seek = currentIndexOfSong < musicList.length - 1 ? currentIndexOfSong + 1 : 0
        setNextOrPreviousMusic(seek)
    }

    const onClickPrevious = () => {
        const currentIndexOfSong = musicList.map(i => i.path).indexOf(music.path)
        const seek = currentIndexOfSong !== 0 ? currentIndexOfSong - 1 : musicList.length - 1
        setNextOrPreviousMusic(seek)
    }

    const onEnded = () => {
        onClickNext()
    }

    return (
        <div className={`audio-player-wrapper`}>
            <H5AudioPlayer
                src={music?.path}
                autoPlay
                controls
                onClickNext={onClickNext}
                onClickPrevious={onClickPrevious}
                showJumpControls={false}
                showSkipControls={true}
                onEnded={onEnded}
            />
        </div>
    )
}

const mapStateToProps = state => (
    {
        music: state.music,
        musicList: state.musicList.songList
    }
)

export default connect(mapStateToProps)(AudioPlayer);