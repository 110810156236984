import React, {useState} from 'react';
import {artisArray} from "../../constants";

const AppCarousel = () => {
    const [state, setState] = useState({
        artists: artisArray
    })

    return (
        <div className={`app-carousel-wrapper`}>
            {state.artists.map(({image, artist}) => {
                return (
                    <div className={`app-carousel-artist-container`}>
                        <img src={image} height='150px' width='150px' style={{borderRadius: '20px'}}/>
                        <b>{artist}</b>
                    </div>
                )
            })
            }
        </div>
    )
}

export default AppCarousel;