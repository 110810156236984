import {songList} from '../../constants'

const initialState = {
    songList
}

export default function (state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}