import song1 from './asseets/ghazal/Dast-e-qatil-Asif Mehdi Hasan(Track-7).mp3'
import song2 from './asseets/ghazal/Ek saathi humko lut gya-Taranum Naaz(Track 9).mp3'
import song3 from './asseets/ghazal/Ghum say ghabrao na- Taranum naaz(Track-3).mp3'
import song4 from './asseets/ghazal/Jalta rehny do- Asif mehdi hasan(track 2).mp3'
import song5 from './asseets/ghazal/Kon kehta hai-Kamar Abbas(Track-10).mp3'
import song6 from './asseets/ghazal/Palkon pay meri chand sitary-Qamar Abbas(Track-4).mp3'
import song7 from './asseets/ghazal/Rah-e-ulfat-Nawaz Mehdi(Track-8).mp3'
import song8 from './asseets/ghazal/Ye raat beegi beegi-Nida Faiz(Track-5).mp3'
import asif_mehdi from "./asseets/artist_images/asif mehdi.jpg"
import taranum_naz from "./asseets/artist_images/taranum naz.jpg"
import nida_faiz from "./asseets/artist_images/nida-faiz.png"
import generic_singer from "./asseets/artist_images/singer.png"
import {ReactComponent as Youtube} from './asseets/svgs/youtube.svg';
import {ReactComponent as Facebook} from './asseets/svgs/facebook.svg';

export const artisArray = [
    {
        artist: "Asif Mehdi Hasan",
        image: asif_mehdi
    }, {
        artist: "Taranum Naaz",
        image: taranum_naz
    }, {
        artist: "Qamar Abbas",
        image: generic_singer
    }, {
        artist: "Nida Faiz",
        image: nida_faiz
    }, {
        artist: "Nawaz Mehdi",
        image: generic_singer
    },
]

export const songList = [{
    title: "Dast-e-qatil",
    artist: "Asif Mehdi Hasan",
    path: song1,
    artist_image: asif_mehdi
}, {
    title: "Ek saathi humko lut gya",
    artist: "Taranum Naaz",
    path: song2,
    artist_image: taranum_naz
}, {
    title: "Kon kehta hai",
    artist: "Qamar Abbas",
    path: song5,
    artist_image: generic_singer
}, {
    title: "Ghum say ghabrao na",
    artist: "Taranum naaz",
    path: song3,
    artist_image: taranum_naz
}, {
    title: "Jalta rehny do",
    artist: "Asif mehdi hasan",
    path: song4,
    artist_image: asif_mehdi
}, {
    title: "Palkon pay meri chand sitary",
    artist: "Qamar Abbas",
    path: song6,
    artist_image: generic_singer
}, {
    title: "Ye raat beegi beegi",
    artist: "Nida Faiz",
    path: song8,
    artist_image: nida_faiz
}, {
    title: "Rah-e-ulfat",
    artist: "Nawaz Mehdi",
    path: song7,
    artist_image: generic_singer
}]

export const socialPlatformsList = [
    {
        Icon: Youtube,
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCOFD_LGYlWvbIYisw764fEg',
        styles: {
            height: '50px',
            width: '50px'
        }
    },
    {
        Icon: Facebook,
        name: 'facebook',
        url: 'https://www.facebook.com/profile.php?id=100008846224746',
        styles: {}
    },
]