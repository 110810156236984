import intro from '../../asseets/ghazal/intro-frough-gilani.mp3'
import frough_gilani from "../../asseets/frough.jpg"

const initialState = {
    path: intro,
    artist: 'Farough Gilani',
    title: 'Introduction',
    parent: 'featured',
    artist_image: frough_gilani
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SET_MUSIC':
            return action.payload
        default:
            return state
    }
}