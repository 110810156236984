import React, {useState, useEffect} from 'react';
import Sidebar from "./sidebar";
import Banner from "./banner";
import MusicSection from "./musicSection";
import Footer from "./footer";

const Dashboard = () => {
    return (
        <div className={`dashboard-wrapper`}>
            <div className={`body-wrapper`}>
                <Sidebar/>
                <div className={`music-section-right`}>
                    <Banner/>
                    <MusicSection/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Dashboard;