import React from 'react';
import MusicTile from "./musicTile";

const MusicList = (props) => {
    return (
        <div>
            {props.list.map((item, idx) => {
                return (
                    <MusicTile
                        item={item}
                        idx={idx}
                        parent={props.parent}
                    />
                )
            })}
        </div>
    )
}

export default MusicList