import React from 'react';
import AudioPlayer from "./audioPlayer";
import MusicTile from "./musicSection/musicTile";
import {connect} from "react-redux";

const Footer = (props) => {
    const {music} = props;

    return (
        <div className={`footer-wrapper`}>
            <div id={'footer-music-tile'} className={`footer-section`} style={{padding: '0 0 0 25px'}}>
                <MusicTile
                    item={
                        {
                            title: music.title,
                            artist: music.artist,
                            artist_image: music.artist_image
                        }
                    }
                    idx={null}
                    disable={true}
                />
            </div>
            <div id={'main-audio-player'} className={`footer-section`}>
                <AudioPlayer/>
            </div>
            <div id={'place-holder-footer'} className={`footer-section`}></div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    music: state.music
})

export default connect(mapStateToProps)(Footer);