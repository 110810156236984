import React from 'react';
import AppCarousel from "./carousel";
import MusicList from "./musicList";
import {songList} from "../../constants";
import featured from "../../asseets/star.png";
import others from "../../asseets/other.png";
import artist from "../../asseets/voice-recorder.png"

const MusicSection = () => {
    const headings = ({title, image}, extraStyles = {}) => {
        return (
            <div style={{...extraStyles}} className={`listingHeading`}><img src={image} height={30}
                                                                            width={30}/><span>{title}</span></div>
        )
    }

    return (
        <div className={`music-section-wrapper`}>
            {headings({title: 'Artists', image: artist}, {marginBottom: 0, marginTop: "10px"})}
            <AppCarousel/>
            <div className={`music-list-wrapper`}>
                <div className={`music-list-left-section`}>
                    {headings({title: 'Featured songs', image: featured})}
                    <MusicList
                        list={songList}
                        parent={'featured'}
                    />
                </div>
                <div className={`music-list-right-section`}>
                    {headings({title: 'Other songs', image: others})}
                    <MusicList
                        list={songList}
                        parent={'other'}
                    />
                </div>
            </div>
        </div>
    )
}

export default MusicSection;