import React, {useState} from 'react';
import {ReactComponent as Menu} from '../asseets/svgs/menu.svg';
import Drawer from "./drawer";
import user from "../asseets/frough.jpg";
import {ContactInfo} from "./common/contactInfo";

const Banner = () => {
    const [state, setState] = useState({
        drawerOpen: false
    })

    const setDrawerState = () => {
        setState({
            drawerOpen: !state.drawerOpen
        })
    }

    const drawerBody = () => {
        return (
            <div className={'contact-drawer-body'}>
                <div className={`main-picture-wrapper-drawer`}>
                    <img height={150} width={150} src={user} alt={''} style={{borderRadius: '20px'}}/>
                    <div className={`main-name`}><span className={`highlights`}>Farough</span> Gilani</div>
                </div>
                <ContactInfo/>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className={`banner-wrapper`}>
                <Menu
                    onClick={setDrawerState}
                    className={'side-menu-icon'}
                    style={{
                        height: '40px',
                        width: '40px',
                    }}
                />
                <div className={`banner-heading`}>Farough-<span className={`highlights`}>e</span>-ghazal</div>
            </div>
            <Drawer
                isOpen={state.drawerOpen}
                onClose={setDrawerState}
                heading={'Contact us'}
                body={drawerBody()}
            />
        </React.Fragment>
    )
}

export default Banner;