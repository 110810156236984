import React from 'react';
import { connect } from "react-redux";
import { musicSetter } from "../../redux/actions/musicActions";

const MusicTile = ({item, idx, dispatch, music, parent, disable = false}) => {
    const setItemInStore = () => {
        console.log({...item, parent})
        dispatch(musicSetter({...item, parent}))
    }

    const isSelected = item.path === music.path && parent === music.parent

    return (
        <div style={{ pointerEvents: disable ? 'none' : '' }} className={isSelected ? `music-list-view-tile-selected` : `music-list-view-tile`} onClick={setItemInStore}>
            {idx !== null && <b>{idx + 1}</b>}
            <img src={item.artist_image} height={60} width={60} style={{borderRadius: '50%'}}/>
            <div className={`music-list-details`}>
                <b>{item.title}</b>
                <p>{item.artist ? item.artist : '--'}</p>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    ...state
})
export default connect(mapStateToProps)(MusicTile);