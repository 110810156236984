import React, {useState} from 'react';
import user from '../asseets/frough.jpg'
import Modal from "./modal";
import Button from "./button";
import contact from "../asseets/mobile-phone.png"
import {ContactInfo} from "./common/contactInfo";

const Sidebar = () => {
    const [state, setState] = useState({
        isOpen: false
    })

    const handleModal = () => {
        setState({
            ...state,
            isOpen: !state.isOpen
        })
    }

    return (
        <React.Fragment>
            <div className={`sidebar-wrapper`}>
                <div className={`main-picture-wrapper`}>
                    <img height={250} width={250} src={user} alt={''} style={{borderRadius: '20px'}}/>
                    <div className={`main-name`}><span className={`highlights`}>Farough</span> Gilani</div>
                </div>
                <Button
                    onClick={handleModal}
                    text={'Contact Us'}
                    icon={contact}
                    styles={{
                        backgroundColor: 'black',
                        marginBottom: '10px',
                        fontWeight: 'bold'
                    }}/>
            </div>
            <Modal
                body={<ContactInfo/>}
                isOpen={state.isOpen}
                onClose={handleModal}
                heading={'Contact Us'}
            />
        </React.Fragment>
    )
}

export default Sidebar;