const Button = ({text, styles, icon = null, ...rest}) => {
    return (
        <div className={'button'} style={{...styles}} {...rest}>
            {
                icon && <img src={icon} height={30}/>
            }
            {text}
        </div>
    )
}

export default Button;