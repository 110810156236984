import {createPortal} from 'react-dom';
import {ReactComponent as Cancel} from "../asseets/svgs/cancel.svg";

const Modal = ({body, isOpen, onClose, heading}) => {
    return (
        <div>
            {
                isOpen && createPortal(<div className={'modalWrapper'}>
                    <div className={'blackScreen'}>
                        <div className={'modalBody'}>
                            <div className={'modalHeader'}>
                                <span>{heading}</span>
                                <Cancel style={{cursor: 'pointer'}} height={30} onClick={onClose}/>
                            </div>
                            {body}
                        </div>
                    </div>
                </div>, document.body)
            }
        </div>
    )
}

export default Modal;